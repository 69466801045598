import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import catalog from "./catalog";
import product from "./product";
import user from "./user";

const reducer = combineReducers({
    catalog,
    product,
    user
});
const store = configureStore({
    reducer,
});
export default store;
