import React from "react";
import {Route, Switch} from "react-router-dom";
import ProductsPage from "./productsPage/productsPage";
import ModelPage from "./modelPage/modelPage";

function Frontend() {
    return (
        <Switch>
            <Route path="/viewer" >
                <ModelPage />
            </Route>
            <Route path="/" >
                <ProductsPage />
            </Route>
        </Switch>
    );
}

export default Frontend;
