import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {isMobile} from 'react-device-detect';

import {Card} from "../components";
import "./productsPage.css";
import {logoutCatalog} from "../../store/catalog";

function ProductsPage() {
    const history = useHistory();
    const dispatch = useDispatch();

    //const catalog = useSelector(state => state.catalog.catalog);
    //const products = useSelector(state => state.product.products);
    const [products, setProducts] = useState([]);

    useEffect(async () => {
        try {
            const data = await fetch("./category/cat.json");
            const response = await data.json();
            setProducts(response.products);
            console.log(JSON.stringify(response));
        } catch (e) {
            console.log(e);
        }
    }, []);

    function onCardClick(product) {
        history.push(`/viewer?model={${product.id}}&env={${product.hdr ? product.hdr : ""}}`);
    }

    return (
        <>
            <div style={{display: "flex", width: "100%", height: "100%"}} className={"list"}>
                {products.map(prod => {
                    console.log(JSON.stringify(prod));
                    return <Card key={prod.id} onCardClick={onCardClick} product={prod} productID={`${prod.id}`} isFlippable={!isMobile} />
                })}
            </div>
        </>
    );
}

export default ProductsPage;
