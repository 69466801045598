import React, {useState} from "react";
import {useHistory} from "react-router-dom";
//import "@google/model-viewer";
import "./modelPage.css";
import {Button} from "../components";
import {useEffect} from "react";

function ModelPage() {
    const history = useHistory();

    useEffect(() => {
        const script = document.createElement('script');

        script.type = "module";
        script.src = "https://unpkg.com/@google/model-viewer@1.8.0/dist/model-viewer.min.js";
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    const queryString = window.location.href.replace("#", "");
    const url = new URL(queryString);
    let model = url.searchParams.get("model");
    let env;

    if (model) {
        model = model.replace(/[{}]/g, "");
        env = "./category/hdr/default.hdr";
        model = "./category/products/" + model + "/model";
    }

    function onBackClick() {
        history.push("/");
    }

    return (
        <div className={"wrapper"}>
            <model-viewer id="model-viewer"
                          src={model + ".glb"}
                          ios-src={model + ".usdz"}
                          ar
                          autoplay
                          ar-scale="auto"
                          environment-image={env}
                          ar-status="not-presenting"
                          alt="iR Viewer"
                          camera-controls >
                <button slot="ar-button" id="ar-button">View in your space</button>
            </model-viewer>
            <Button style={{position: "absolute", left: "20px", top: "20px"}} text={"Back"} onButtonClick={onBackClick} />
        </div>
    );
}

export default ModelPage;
