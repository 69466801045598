import React from "react";
import "./button.css";

function Button(props) {

    function onButtonClick() {
        if (props.onButtonClick)
            props.onButtonClick();
    }

    return (
        <button style={props.style} onClick={onButtonClick}>{props.text}</button>
    );
}

export default Button;
