import React, {useRef, useState} from "react";
import "./card.css";
import ReactCardFlip from "react-card-flip";
//import QRCode from "react-qr-code";
import { QRCode } from "react-qrcode-logo";
import html2canvas from "html2canvas";

function Card(props) {
    const [isFlipped, setIsFlipped] = useState(false);
    const downloadableQR = useRef();

    const url = `${window.location.href}viewer?model={${props.productID}}`;

    function onCardClick() {
        if (props.onCardClick)
            props.onCardClick(props.product);
    }

    function onFlipClick(e) {
        e.stopPropagation();
        setIsFlipped(!isFlipped);
    }

    function onDelete(e) {
        e.stopPropagation();
        if (props.onProductDelete)
            props.onProductDelete(props.product);
    }

    function onDownloadQR(e) {
        e.stopPropagation();

        html2canvas(downloadableQR.current.childNodes[0])
            .then(function (canvas) {
                const link = document.createElement('a');
                link.download = 'qrcode.png';
                link.href = canvas.toDataURL("image/png");
                link.click();
            });
    }

    return (
        <ReactCardFlip isFlipped={isFlipped}>
            <div className={props.product.state === "2" ? "disabledCard" : "card"} onClick={onCardClick}>
                {props.canBeDeleted ? <button id="delete-button" onClick={onDelete} /> : null}
                {props.isFlippable ? <button id="flip-button" onClick={onFlipClick} /> : null}
                <img
                    style={{maxHeight: "180px"}}
                    src={"./category/products/" + props.productID + "/image.jpg"}
                    alt={"Not found"}
                />
                <p id={"cardName"}>{props.product.name}</p>
            </div>
            <div className={props.product.state === "2" ? "disabledCard" : "card"} onClick={onCardClick}>
                {props.isFlippable
                    ? <>
                        <button id="back-button" onClick={onFlipClick} />
                        <QRCode
                            enableCORS={true}
                            value={url}
                            logoImage={"https://media-exp1.licdn.com/dms/image/C4D0BAQEt8k9RIIRKiQ/company-logo_200_200/0/1541585317662?e=2159024400&v=beta&t=TN9joazlZ3tQ5x_cKwM-aEFpy-wdexAivbzrvQoj0tA"}
                            logoWidth={40}
                            logoHeight={40}
                            qrStyle={"dots"}
                            size={150}
                        />
                        <button style={{width: "150px", height: "30px", fontSize: "14px"}} onClick={onDownloadQR}>Download QR Code</button>
                    </>
                    : null }
                <div ref={downloadableQR} style={{position: "absolute", top: "-1500px", left: "-1500px"}} >
                    {props.isFlippable ? <QRCode
                        enableCORS={true}
                        value={url}
                        logoImage={"https://media-exp1.licdn.com/dms/image/C4D0BAQEt8k9RIIRKiQ/company-logo_200_200/0/1541585317662?e=2159024400&v=beta&t=TN9joazlZ3tQ5x_cKwM-aEFpy-wdexAivbzrvQoj0tA"}
                        logoWidth={204}
                        logoHeight={204}
                        qrStyle={"dots"}
                        size={1000}
                    /> : null }
                </div>
            </div>
        </ReactCardFlip>
    );
}

export default Card;
