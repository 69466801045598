import { createSlice } from "@reduxjs/toolkit";
import { API } from "../utils";

const getToken = () => {
    const itemStr = localStorage.getItem("token");
    if (!itemStr) {
        return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();
    if (now.getTime() > item.expiry) {
        localStorage.removeItem("token");
        return null;
    }
    return item.value;
};

// Slice
const slice = createSlice({
    name: "user",
    initialState: {
        name: null,
        token: getToken(),
        error: null,
    },
    reducers: {
        loginSuccess: (state, action) => {
            state.name = action.payload.username;
            state.token = action.payload.token;
            state.error = null;

            const expiration = new Date();
            expiration.setDate(expiration.getDate() + 1);

            const item = {
                value: state.token,
                expiry: expiration.getTime(),
            }
            localStorage.setItem("token", JSON.stringify(item));
        },
        logoutSuccess: (state) => {
            state.name = null;
            state.token = null;
            localStorage.removeItem("token");
        },
        onError: (state, action) => {
            state.error = action.payload.error;
        },
    },
});

// Actions
const { loginSuccess, logoutSuccess, onError } = slice.actions;

export const verifyToken = ({ token }) => {
    return async dispatch => {
        try {
            API.defaults.headers.common["Authorization"] = token;
            API.get("/authenticate/token")
                .then(res => {
                    dispatch(loginSuccess({ username: res.data.username, token }));
                })
                .catch(error => console.error(error));
        } catch (e) {
            console.error(e.message);
        }
    };
};

export const login = ({ username, password }) => {
    return async dispatch => {
        try {
            API.post("/authenticate", { username, password })
                .then(res => {
                    const token = res.data.data;
                    window.localStorage.setItem("auth_token", token);
                    API.defaults.headers.common["Authorization"] = token;

                    dispatch(loginSuccess({ username, token }));
                })
                .catch(error => dispatch(onError({error: error.response.data.message})));
        } catch (e) {
            console.error(e.message);
        }
    };
};

export const logout = () => async dispatch => {
    try {
        return dispatch(logoutSuccess());
    } catch (e) {
        return console.error(e.message);
    }
};

export default slice.reducer;