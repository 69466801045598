import axios from "axios";

const localURL = "http://localhost:3035";
const remoteURL = "https://node.sense-ir.xyz:3035/";

const API = axios.create({
    baseURL: remoteURL,
});

export default API;
