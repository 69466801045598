import { createSlice } from "@reduxjs/toolkit";
import { API } from "../utils";

// Slice
const slice = createSlice({
    name: "product",
    initialState: {
        currentProduct: {},
        products: [],
        fetching: false,
        error: null,
    },
    reducers: {
        productsRetrieved: (state, action) => {
            state.products = action.payload.products;
            state.fetching = false;
            state.error = null;
        },
        productUploaded: (state, action) => {
            state.currentProduct = action.payload.product;
            if (state.products.find(p => p.id == state.currentProduct.id) == null)
                state.products.push(state.currentProduct);
            state.fetching = false;
            state.error = null;
        },
        setFetchingState: (state, action) => {
            state.fetching = action.payload.fetching;
        },
        onError: (state, action) => {
            state.error = action.payload.error;
            state.fetching = false;
        },
    },
});

// Actions
export const {
    productsRetrieved,
    productUploaded,
    setFetchingState,
    onError,
} = slice.actions;

export const getProducts = (catalog) => {
    return async dispatch => {
        try {
            dispatch(setFetchingState({ fetching: true }));
            API.get(`/products/${catalog}`)
                .then(res => {
                    const products = res.data.data;
                    dispatch(productsRetrieved({ products }));
                })
                .catch(error => {
                    dispatch(onError({error: error.response.data.message}));
                });
        } catch (e) {
            dispatch(onError({error: e.message}));
        }
    };
};

export const uploadProduct = (catalogID, files, name, state, productID, showEnvironments) => {
    return async dispatch => {
        try {
            let data = new FormData();
            files.forEach(file => {
                data.append("file", file.file);
                data.append("type", file.type);
            });

            data.append("name", name);
            data.append("state", state);
            data.append("show_environments", showEnvironments);
            if (productID != null)
                data.append("productID", productID);

            dispatch(setFetchingState({ fetching: true }));

            API.post("/product/" + catalogID, data)
                .then(res => {
                    const product = res.data.data;
                    dispatch(productUploaded({product: product}));
                })
                .catch(error => {
                    dispatch(onError({error: error.message}));
                });
        } catch (e) {
            dispatch(onError({error: e.message}));
        }
    };
};

export const deleteProduct = (catalogID, productID) => {
    return async dispatch => {
        try {
            dispatch(setFetchingState({ fetching: true }));
            API.delete("/product", { headers: {}, data : { catalog: catalogID, product: productID } })
                .then(res => {
                    dispatch(getProducts(catalogID));
                })
                .catch(error => {
                    dispatch(onError({error: error.message}));
                });
        } catch (e) {
            dispatch(onError({error: e.message}));
        }
    };
};

export default slice.reducer;
