import { createSlice } from "@reduxjs/toolkit";
import { API } from "../utils";

// Slice
const slice = createSlice({
    name: "catalog",
    initialState: {
        catalog: {},
        catalogs: null,
        fetching: false,
        error: null,
    },
    reducers: {
        catalogRetrieved: (state, action) => {
            state.catalog = action.payload.catalog;
            state.fetching = false;

            const expiration = new Date();
            expiration.setDate(expiration.getDate() + 1);

            const item = {
                value: action.payload.code,
                expiry: expiration.getTime(),
            }
            localStorage.setItem("code", JSON.stringify(item));
        },
        catalogsRetrieved: (state, action) => {
            state.catalogs = action.payload.catalogs;
            state.fetching = false;
        },
        setFetchingState: (state, action) => {
            state.fetching = action.payload.fetching;
        },
        onError: (state, action) => {
            state.error = action.payload.error;
            state.fetching = false;
        },
        logoutCatalogSuccess: (state, action) => {
            state.catalog = null;
            localStorage.removeItem("code");
        }
    },
});

// Actions
export const {
    catalogRetrieved,
    catalogsRetrieved,
    setFetchingState,
    onError,
    logoutCatalogSuccess,
} = slice.actions;

export const getCatalog = code => {
    return async dispatch => {
        try {
            dispatch(setFetchingState({ fetching: true }));
            API.get(`/catalog/${code}`)
                .then(res => {
                    const catalog = res.data.data;
                    dispatch(catalogRetrieved({ catalog: catalog, code: code }));
                })
                .catch(error => {
                    dispatch(onError({error: error.response.data.message}));
                });
        } catch (e) {
            dispatch(onError({error: e.message}));
        }
    };
};

export const getCatalogs = () => {
    return async dispatch => {
        try {
            dispatch(setFetchingState({ fetching: true }));
            API.get(`/catalogs`)
                .then(res => {
                    const catalogs = res.data.data;
                    dispatch(catalogsRetrieved({ catalogs: catalogs }));
                })
                .catch(error => {
                    dispatch(onError({error: error.response.data.message}));
                });
        } catch (e) {
            dispatch(dispatch(onError({error: e.message})));
        }
    };
};

export const newCatalog = (name) => {
    return async dispatch => {
        try {
            dispatch(setFetchingState({ fetching: true }));
            API.post(`/catalog`, {name})
                .then(() => {
                    dispatch(getCatalogs());
                })
                .catch(error => {
                    dispatch(dispatch(onError({error: error.message})));
                });
        } catch (e) {
            dispatch(dispatch(onError({error: e.message})));
        }
    };
};

export const logoutCatalog = () => {
    return async dispatch => {
        try {
            return dispatch(logoutCatalogSuccess());
        } catch (e) {
            return console.error(e.message);
        }
    };
};

export default slice.reducer;
